import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import LoginCnt from '../containers/LoginCnt';
import OlvideMiClaveCnt from '../containers/OlvideMiClaveCnt'
import HomeCnt from '../containers/HomeCnt';
import NewFormCnt from '../containers/NewFormCnt';
import ListCnt from '../containers/ListCnt';
import ListAdminCnt from '../containers/ListAdminCnt';
import ChangePasswordCnt from '../containers/ChangePasswordCnt';
import ChangePasswordLoggedCnt from '../containers/ChangePasswordLoggedCnt';
import CreateUserCnt from '../containers/CreateUserCnt';
import SuccessCnt from '../containers/SuccessCnt';
import DownloadCSVCnt from '../containers/DownloadCSVCnt';
import * as URLMAPPING from '../config/UrlMapping';

import Layout from "../components/Layout";
const Root = ({ store }) => (
  <Provider store={store}>
    <div>
    <BrowserRouter>
      <Switch>
        <Route path={"/ping"}>pong</Route>
        <Route exact path={URLMAPPING.LOGIN_ROOT}             component={LoginCnt} />
        <Route exact path={URLMAPPING.LOGIN}       component={LoginCnt} />
        <Route path={URLMAPPING.OLVIDE_MI_CLAVE}   component={OlvideMiClaveCnt} />
        <Route path={URLMAPPING.CHANGE_PASSWORD + "/:token"}  component={ChangePasswordCnt} />
        <Layout>
          <Route path={URLMAPPING.HOME}             component={HomeCnt} />
          <Route path={URLMAPPING.LIST}              component={ListCnt} />
          <Route path={URLMAPPING.CREATE}            component={NewFormCnt} />
          <Route path={URLMAPPING.CREATE_USER}        component={CreateUserCnt} />
          <Route path={URLMAPPING.LIST_ADMIN}         component={ListAdminCnt} />
          <Route path={URLMAPPING.SUCCESS}            component={SuccessCnt} />
          <Route path={URLMAPPING.CAMBIAR_PASSWORD_LOGGED}            component={ChangePasswordLoggedCnt} />
          <Route path={URLMAPPING.DOWNLOAD_CSV}            component={DownloadCSVCnt} />
        </Layout>
      </Switch>
    </BrowserRouter>
    </div>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default Root
